import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import { apiConstants } from "../../../components/Constant/constants";

Geocode.setApiKey(apiConstants.google_api_key);
Geocode.enableDebug();

class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mapPosition: {
                lat: 0,
                lng: 0
            },
            markerPosition: {
                lat: 0,
                lng: 0
            }
        }
    }

    componentDidMount() {
        if (this.props.pickCurrentLocation && navigator.geolocation) {
            let self = this;
            navigator.geolocation.getCurrentPosition(function (position) {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                self.setState({
                    mapPosition: pos,
                    markerPosition: pos,
                })
            })
        } else if(!this.props.pickCurrentLocation) {
            this.setState({
                mapPosition: this.props.center,
                markerPosition: this.props.center,
            })
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.pickCurrentLocation && prevState.mapPosition.lat !== this.state.mapPosition.lat) {
            Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
                response => {
                    const address = response.results[0].formatted_address,
                        addressArray = response.results[0].address_components,
                        city = this.getCity(addressArray),
                        street_details = this.getArea(addressArray),
                        state = this.getState(addressArray),
                        country = this.getCountry(addressArray),
                        zipcode = this.getZipCode(addressArray);
                    this.props.updateFormDetails({
                        full_address: (address) ? address : '',
                        street_details: (street_details) ? street_details : '',
                        city: (city) ? city : '',
                        state: (state) ? state : '',
                        country: (country) ? country : '',
                        zipcode: (zipcode) ? zipcode : 0,
                        latitude: this.state.mapPosition.lat,
                        longitude: this.state.mapPosition.lng,
                    })
                },
                error => {
                    console.error(error);
                }
            );
        } else if(!this.props.pickCurrentLocation) {
            Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
                response => {
                    this.props.updateFormDetails();
                })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.markerPosition.lat !== nextState.markerPosition.lat) {
            return true
        } else {
            return false
        }
    }

    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_3' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    getArea = (addressArray) => {
        let street_details = [];
        addressArray.forEach(address => {
            if(address.types.indexOf("premise") > -1) {
                street_details =street_details.concat(address.long_name);
            }
            if(address.types.indexOf("neighborhood") > -1) {
                street_details =street_details.concat(address.long_name);
            }
            if(address.types.indexOf("sublocality_level_2") > -1) {
                street_details =street_details.concat(address.long_name);
            }
            if(address.types.indexOf("sublocality_level_1") > -1) {
                street_details =street_details.concat(address.long_name);
            }
        })
        return street_details.join(', ');
    };

    getZipCode = (addressArray) => {
        let zipcode = 0;
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
                zipcode = addressArray[i].long_name;
                return zipcode;
            }
        }
    };

    getCountry = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    onMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    street_details = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    country = this.getCountry(addressArray),
                    zipcode = this.getZipCode(addressArray);
                this.setState({
                    markerPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                })
                this.props.updateFormDetails({
                    full_address: (address) ? address : '',
                    street_details: (street_details) ? street_details : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    country: (country) ? country : '',
                    zipcode: (zipcode) ? zipcode : '',
                    latitude: newLat,
                    longitude: newLng,
                })
            },
            error => {
                console.error(error);
            }
        );
    };

    onPlaceSelected = (place) => {
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            street_details = this.getArea(addressArray),
            state = this.getState(addressArray),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng(),
            country = this.getCountry(addressArray),
            zipcode = this.getZipCode(addressArray);

        this.setState({
            markerPosition: {
                lat: latValue,
                lng: lngValue
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        })
        this.props.updateFormDetails({
            full_address: (address) ? address : '',
            street_details: (street_details) ? street_details : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            country: (country) ? country : '',
            zipcode: (zipcode) ? zipcode : '',
            latitude: latValue,
            longitude: lngValue,
        })
    };


    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap google={this.props.google}
                        defaultZoom={this.props.zoom}
                        defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                    >
                        <Marker google={this.props.google}
                            name={'Dolores park'}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                        />
                    </GoogleMap>
                )
            )
        );
        return (
            <div>
                <AsyncMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiConstants.google_api_key}&libraries=places`}
                    loadingElement={
                        <div style={{ height: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: this.props.height }} />
                    }
                    mapElement={
                        <div style={{ height: `100%` }} />
                    }
                />
            </div>
        )
    }
}
export default Map;
