export const apiConstants = {
  // Common variables

  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  // Song download status

  DOWNLOAD_INITIATE_STAUTS: 1,

  DOWNLOAD_PROGRESSING_STAUTS: 2,

  DOWNLOAD_PAUSE_STAUTS: 3,

  DOWNLOAD_COMPLETE_STAUTS: 4,

  DOWNLOAD_CANCEL_STAUTS: 5,

  // Wishlist status

  WISHLIST_CLEAR_ALL: 1,

  WISHLIST_CLEAR_SINGLE_AUDIO: 0,

  // History status

  HISTORY_CLEAR_ALL: 1,

  HISTORY_CLEAR_SINGLE_AUDIO: 0,

  // PAGE TYPES

  PRIVACY_PAGE_TYPE: "privacy",

  TERMS_PAGE_TYPE: "terms",

  // Social Login Credentials

  GOOGLE_CLIENT_ID: "",

  FACEBOOK_APP_ID: "",

  // Hisotry status

  HISTORY_INITIATE_STATUS: 1,

  HISTORY_COMPLETE_STATUS: 2,

  HISTORY_DELETE_STATUS: 3,

  USER_API_CALL: "/user",

  HOST_API_CALL: "/provider",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  settingsUrl: "https://admin.parkingpal.in/api/settings",
  // settingsUrl: "https://admindev.parkingpal.in/api/settings",

  google_api_key: "AIzaSyBxwp2ySJf_iv7cjbOXIMi4M6LUnui6T_I",

  socketUrl: "https://admin-rentpark.rentcubo.info:3001/",
  
  BOOKING_CANCELLED_BY_USER: 4,
  
  BOOKING_CANCELLED_BY_PROVIDER: 5,
};

export const VEHILE_NUMBER_MASK = 'aa 99 aa 9999';


export const FACEBOOK_CLIENT_ID = '995984384533358';
export const GOOGLE_CLIENT_ID = '632176981378-080rsmh39g4lga60njlqsjbrdil86cvj.apps.googleusercontent.com';//'632176981378-dm39a3e67v4fktcv51tteak3vgo2cff8.apps.googleusercontent.com';
