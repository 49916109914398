import React, { Component } from "react";
import Loader from "../../Helper/Loader";
import { Link, Redirect } from "react-router-dom";
import api from "../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import { translate, t } from "react-multi-lang";

class HostSubscriptionInvoice extends Component {
  state = {
    loading: true,
    redirect: false
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ loading: false });
    } else {
      window.location = "/host/dashboard";
    }
  }

  payNow = (event, subscription) => {
    event.preventDefault();
    var priceCalData = {
      amount: subscription.amount,
      currency: "INR",
      userId:
        localStorage.getItem("hostId") !== "" &&
          localStorage.getItem("hostId") !== null &&
          localStorage.getItem("hostId") !== undefined
          ? localStorage.getItem("hostId")
          : ""
    };
    api
      .postMethod("createorder", priceCalData)
      .then((response) => {
        if (response.data.success) {
          console.log("response:::", response.data.data);
          response.data.data["handler"] = async (response) => {
            try {
              const paymentId = response.razorpay_payment_id;
              console.log(response);
              api
                .postMethod("paymentverify", {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature
                })
                .then((response1) => {
                  console.log(response1);
                  if (!response1['data']['error']) {
                    setTimeout(() => {
                      api
                        .postMethod("subscriptions_payment_by_stripe", {
                          provider_subscription_id: subscription.provider_subscription_id,
                          razorpay_payment_id:response.razorpay_payment_id
                        })
                        .then(response => {
                          if (response.data.success === true) {
                            this.setState({
                              redirect: true
                            });
                            ToastDemo(this.props.toastManager, response.data.message, "success");
                          } else {
                            ToastDemo(this.props.toastManager, response.data.error, "error");
                          }
                        });
                    }, 1000);
                  }
                  else {
                    ToastDemo(this.props.toastManager, response1['data']['message'], "error");
                  }
                });
            } catch (err) {
              ToastDemo(this.props.toastManager, "Payment Failed", "error");
            }
          };
          response.data.data["modal"] = {
            "ondismiss": () => {
              console.log('Checkout form closed');
            }
          };
          const rzp1 = new window.Razorpay(response.data.data);
          rzp1.open();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          // this.setState({
          //   loadingContent: null,
          //   buttonDisable: false,
          // });
        }
        // this.setState({ priceCalData });
        // setTimeout(() => {
        //   this.BookingsCreate();
        // }, 1000);
      });

  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/host/subscription/success"} />;
    }
    let loader = new Loader();
    const { loading } = this.state;
    if (loading) {
      return loader.propertyLoader();
    } else {
      const subscription = this.props.location.state;

      return (
        <div className="main">
          <div className="container">
            <div className="top-bottom-spacing">
              <div>
                <h3 className="subscriptions-tit">{t("invoice")}</h3>
                <div className="sub-line" />
                <div className="invoice">
                  <h3>{subscription.title}</h3>
                  <p>{subscription.description}</p>
                  <div className="invoice-table table-responsive">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <b>{t("price")}</b>
                          </td>
                          <td className="text-right">
                            {subscription.currency}
                            {subscription.amount}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <th>{t("total")}</th>
                        <th className="text-right">
                          {subscription.currency}
                          {subscription.amount}
                        </th>
                      </tfoot>
                    </table>
                  </div>

                  <div className="invoice-pay">
                    <Link
                      to="#"
                      className="green-btn invoice-pay-btn small-btn"
                      onClick={e => this.payNow(e, subscription)}
                    >
                      {t("pay_now")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withToastManager(translate(HostSubscriptionInvoice));
