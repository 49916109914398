import React, { Component } from "react";
import { GoogleApiWrapper, InfoWindow, Marker, Map, Polygon } from "google-maps-react";
import Slider from "react-slick";
import Helper from "./Helper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactDOM from "react-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "./toaster";
import { apiConstants } from "../../components/Constant/constants";

const mapStyles = {
    width: "100%",
    height: "100%"
};

const parking_lat_lngs = [[
    {lat: 18.631985, lng: 73.797230},
    {lat: 18.631891, lng: 73.797263},
    {lat: 18.633982, lng: 73.798366},
    {lat: 18.634076, lng: 73.798136}
],[
    {lat: 18.628719, lng: 73.803356},
    {lat: 18.628784, lng: 73.803362},
    {lat: 18.627915, lng: 73.804094},
    {lat: 18.627878, lng: 73.804055}
]
];
const parking_lat_lngs_marker = [
    { lat: 18.628092, lng: 73.803864 },  
    { lat: 18.633325, lng: 73.797911 }
];
const not_parking_lat_lngs = [[
    {lat: 18.629859, lng: 73.802801},
    {lat: 18.629811, lng: 73.802973},
    {lat: 18.626568, lng: 73.805698},
    {lat: 18.626507, lng: 73.805569}
]
];   
const not_parking_lat_lngs_marker = [
    { lat: 18.628536, lng: 73.803938 }
]
export class MapContainer extends Helper {

    constructor(props){
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            content: {},
            loading: true,
            currentLocation: {
                lat: 12.9121,
                lng: 77.6446
            }
        };
        
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            loading: false
        });

    }
    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
                loading: true
            });
        }
    };

    showParkingDetails = (event, content) => {
        event.preventDefault();
        if (localStorage.getItem("userLoginStatus"))
            this.props.history.push("/payment", 
            {
                singleDetails: content,
                formData: this.props.formData,
            });
        else
            ToastDemo(
                this.props.toastManager,
                "Please login to continue",
                "error"
            );
    };

    centerMoved = (mapProps, map) => {
        console.log("Map moved lat", map.center.lat());
        console.log("Map moved lng", map.center.lng());
        this.props.onMapPanned(map.center.lat(), map.center.lng());
    };

    onInfoWindowOpen(props, e) {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
        };
        const button = (
            <div className="row">
                <div className="subcategory-card">
                    <div className="relative">
                        <section className="">
                            <Slider {...settings}>
                                <div>
                                    <div className="homes-img-sec1">
                                        <img
                                            src={
                                                this.state.activeMarker.content
                                                    .space_picture
                                            }
                                            alt="homes-img"
                                            className="homes-img"
                                        />
                                    </div>
                                </div>
                            </Slider>
                        </section>
                    </div>

                    <a
                        href="#"
                        onClick={event =>
                            this.showParkingDetails(
                                event,
                                this.state.activeMarker.content
                            )
                        }
                    >
                        <div
                            className="homes-text-sec"
                            // onClick={this.showParkingDetails}
                        >
                            <p className="red-text">
                                {this.state.activeMarker.content.host_location}{" "}
                            </p>
                            <h4 className="homes-title">
                                {this.state.activeMarker.content.host_name}
                            </h4>
                            <h5 className="homes-price ">
                                <span>
                                    {
                                        this.state.activeMarker.content
                                            .per_hour_formatted
                                    }{" "}
                                    {
                                        this.state.activeMarker.content
                                            .per_hour_symbol
                                    }
                                </span>{" "}
                            </h5>
                            <h5 className="rating-sec">
                                <span className="rating-star">
                                    {this.starRatingHost(
                                        this.state.activeMarker.overall_ratings,
                                        12
                                    )}
                                </span>
                                <span>
                                    &nbsp;(
                                    {
                                        this.state.activeMarker.content
                                            .total_ratings
                                    }
                                    )
                                </span>
                            </h5>
                        </div>
                    </a>
                </div>
            </div>
        );
        ReactDOM.render(
            React.Children.only(button),
            document.getElementById("displayParking")
        );
    }

    render() {
        const { loading } = this.state;

        return (
            <Map
                google={this.props.google}
                zoom={14}
                style={mapStyles}
                initialCenter={this.props.currentLocation}
                centerAroundCurrentLocation={false}
                onDragend={this.centerMoved}
                className="map-resp"
            >
                {/* {parking_lat_lngs.map(parking_lat_lng => (
                    <Polygon
                        paths={parking_lat_lng}
                        strokeColor="#4caf50"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#4caf50"
                        fillOpacity={0.35} />
                ))}
                {parking_lat_lngs_marker.map(parking_lat_lng => (
                <Marker
                    name={'Your position'}
                    position={parking_lat_lng}
                    icon={{
                    url: window.location.origin +
                            "/assets/mapicons/parking-64.png",
                    scaledSize: {height:32,width:32}
                    }} />
                ))}
                {not_parking_lat_lngs.map(not_parking_lat_lng => (
                    <Polygon
                        paths={not_parking_lat_lng}
                        strokeColor="#f70713"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#f70713"
                        fillOpacity={0.35} />
                ))}
                {not_parking_lat_lngs_marker.map(not_parking_lat_lng => (
                <Marker
                    name={'Your position'}
                    position={not_parking_lat_lng}
                    icon={{
                    url: window.location.origin +
                            "/assets/mapicons/no-parking-48.png",
                    scaledSize: {height:32,width:32}
                    }} />
                ))} */}
                {this.props.data.map(content => (
                    <Marker
                        key={content.host_id}
                        onClick={this.onMarkerClick}
                        name={content.host_name}
                        title={content.host_name}
                        content={content}
                        label={{
                            text: `${content.per_hour_formatted}/hr`,
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#fff"
                        }}
                        icon={{
                            url:
                                window.location.origin +
                                "/assets/mapicons/black-map.svg"
                        }}
                        position={{
                            lat: content.latitude,
                            lng: content.longitude
                        }}
                    />
                ))}
                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                    onClose={this.onClose}
                    onOpen={e => {
                        this.onInfoWindowOpen(this.props, e);
                    }}
                >
                    {loading ? "" : <div id="displayParking" />}
                </InfoWindow>
            </Map>
        );
    }
}

export default withToastManager(
    GoogleApiWrapper({
        apiKey: apiConstants.google_api_key
    })(MapContainer)
);
